import styled from "styled-components"
import Img from "gatsby-image"

export const StyledAdvert = styled.section`
  height: 500px;
  width: 100%;
  overflow: hidden;
  ${({ theme }) => theme.media.tablet} {
    height: 400px;
  }
  ${({ theme }) => theme.media.mobile} {
    width: 100%;
    height: auto;
    margin-top: 50px;
  }
`

export const BackgroundImage = styled(Img)`
  width: 60%;
  height: 100%;
  position: absolute;
  left: 0;
  ${({ theme }) => theme.media.tablet} {
    background-size: cover;
  }
  ${({ theme }) => theme.media.mobile} {
    display: none;
  }
`

export const MobileBackgroundImage = styled(Img)`
  display: none;
  ${({ theme }) => theme.media.mobile} {
    display: block;
    width: 250%;
    height: 500px;
    background-size: 100%;
    right: 200px;
  }
`
