import React from "react"
import {
  StyledProductsText,
  StyledParagraph,
  StyledInfo,
  StyledButton,
} from "./ProductsTextStyles"
import Heading from "../../atoms/Heading/Heading"

const ProductsTextAd = ({ headingTitle, children, extraText, buttonName }) => (
  <StyledProductsText>
    <Heading level="3">{headingTitle}</Heading>
    <StyledParagraph>{children}</StyledParagraph>
    <StyledInfo>{extraText}</StyledInfo>
    <StyledButton as="a" href="https://offex.pl/ofd-en.html" target="_blank">
      {buttonName}
    </StyledButton>
  </StyledProductsText>
)
export default ProductsTextAd
