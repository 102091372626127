import styled from "styled-components"

export const Button = styled.button`
  color: ${({ theme }) => theme.colors.black};
  font-weight: ${({ theme }) => theme.font.weight.bold};
  background-color: ${({ theme }) => theme.colors.white};
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: ${({ theme }) => theme.font.paragraphSize.xs};
  outline: none;
  cursor: pointer;
  text-decoration: none;
`
