import styled from "styled-components"
import { Paragraph } from "../../atoms/Paragraph/ParagraphStyles"

export const StyledProductsTitle = styled.div`
  width: 58%;
  height: 300px;
  margin: 150px 0 0px 0;
  ${({ theme }) => theme.media.widescreen} {
    width: 80%;
  }
  ${({ theme }) => theme.media.desktop} {
    width: 100%;
  }
`

export const StyledParagraph = styled(Paragraph)`
  width: 100%;
  ${({ theme }) => theme.media.widescreen} {
    width: 80%;
  }
  ${({ theme }) => theme.media.desktop} {
    width: 80%;
  }
  ${({ theme }) => theme.media.mobile} {
    font-size: ${({ theme }) => theme.font.paragraphSize.s};
    width: 80%;
  }
`
