import React from "react"
import {
  StyledWarrantyText,
  StyledHeading,
  StyledParagraph,
} from "./WarrantyTextStyles"

const WarrantyText = ({ headingTitle, children }) => (
  <StyledWarrantyText>
    <StyledHeading level="1">{headingTitle}</StyledHeading>
    <StyledParagraph>{children}</StyledParagraph>
  </StyledWarrantyText>
)
export default WarrantyText
