import React from "react"
import {
  StyledHeaderText,
  StyledButton,
  StyledParagraph,
  StyledHeading,
} from "./HeaderTextStyles"
import { FlexBox } from "../../atoms/FlexBox/FlexBoxStyles"

const HeaderText = ({ children, title, buttonName }) => (
  <StyledHeaderText>
    <FlexBox
      flexDirection="column"
      justifyContent="space-around"
      alignItems="flex-start"
      alignItemsTablet="center"
    >
      <StyledHeading level="1">{title}</StyledHeading>
      <StyledParagraph>{children}</StyledParagraph>
      <StyledButton as="a" href="/#products">
        {buttonName}
      </StyledButton>
    </FlexBox>
  </StyledHeaderText>
)
export default HeaderText
