import React, { useState } from "react"
import {
  StyledHeader,
  BackgroundImage,
  StyledWrapper,
  Image,
  HeaderBox,
} from "./HeaderStyles"
import Navigation from "../../molecules/Navigation/Navigation"
import { graphql, useStaticQuery } from "gatsby"
import HeaderText from "../../molecules/HeaderText/HeaderText"
import LogoImg from "../../../images/logo/Logo.svg"
import NavigationMobile from "../../molecules/NavigationMobile/NavigationMobile"
import Wrapper from "../../atoms/Wrapper/Wrapper"
import { FlexBox } from "../../atoms/FlexBox/FlexBoxStyles"

const Header = () => {
  const [isActive, setIsActive] = useState(false)

  const handleShowHide = () => {
    if (!isActive) {
      setIsActive(true)
      document.body.style.overflow = "hidden"
    } else {
      setIsActive(false)
      document.body.style.overflow = ""
    }
  }

  const data = useStaticQuery(query)

  const imageData = data.desktop.childImageSharp.fluid

  return (
    <HeaderBox>
      {isActive ? (
        <NavigationMobile onClick={handleShowHide}></NavigationMobile>
      ) : null}
      <BackgroundImage
        imgStyle={{ objectPosition: "80%" }}
        id="offroad-fabrication"
        fluid={imageData}
        alt="two offroad cars driving through the forest in the mist"
      />
      <StyledHeader>
        <FlexBox flexDirection="column" alignItems="center">
          <Wrapper>
            <Navigation onClick={handleShowHide}></Navigation>
          </Wrapper>
          <StyledWrapper>
            <FlexBox
              flexDirection="row"
              justifyContent="space-between"
              alignItems="flex-start"
            >
              <HeaderText title="Offroad Fabrication" buttonName="Our products">
                What lies beneath a thick sheet of metal is a design, form and
                technology all join together for the best function on the
                market.
              </HeaderText>
              <Image src={LogoImg} alt="ofd company logo" />
            </FlexBox>
          </StyledWrapper>
        </FlexBox>
      </StyledHeader>
    </HeaderBox>
  )
}

export const query = graphql`
  query {
    desktop: file(relativePath: { eq: "header.jpg" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`

export default Header
