import styled from "styled-components"
import { Paragraph } from "../../atoms/Paragraph/ParagraphStyles"
import { Button } from "../../atoms/Button/ButtonStyles"

export const StyledProductsText = styled.div`
  width: 60%;
  height: auto;
  margin: 50px 0;
  overflow-x: hidden;
  ${({ theme }) => theme.media.widescreen} {
    width: 80%;
  }
  ${({ theme }) => theme.media.desktop} {
    width: 80%;
  }
`

export const StyledParagraph = styled(Paragraph)`
  font-size: ${({ theme }) => theme.font.paragraphSize.xs};
  margin: 40px 0;
  width: 94%;
  ${({ theme }) => theme.media.widescreen} {
    width: 90%;
    word-wrap: wrap;
  }
`

export const StyledInfo = styled(Paragraph)`
  color: ${({ theme }) => theme.colors.white};
  margin: 0 0 40px 0;
  width: 70%;
  ${({ theme }) => theme.media.mobile} {
    width: 80%;
  }
`

export const StyledButton = styled(Button)`
  width: 165px;
  height: 44px;
`
