import React from "react"
import Header from "../components/organisms/Header/Header"
import Products from "../components/organisms/Products/Products"
import Advert from "../components/organisms/Advert/Advert"
import BecomeLeader from "../components/organisms/BecomeLeader/BecomeLeader"
import Warranty from "../components/organisms/Warranty/Warranty"
import Contact from "../components/organisms/Contact/Contact"
import Footer from "../components/organisms/Footer/Footer"

const Template = () => (
  <>
    <Header></Header>
    <Products></Products>
    <Advert></Advert>
    <BecomeLeader></BecomeLeader>
    <Warranty></Warranty>
    <Contact></Contact>
    <Footer></Footer>
  </>
)

export default Template
