import styled from "styled-components"
import { Paragraph } from "../../atoms/Paragraph/ParagraphStyles"

export const StyledContactInfo = styled.div`
  width: 50%;
  ${({ theme }) => theme.media.desktop} {
    width: 90%;
    margin-bottom: 70px;
    margin-top: -60px;
  }
`
export const StyledParagraph = styled(Paragraph)`
  ${({ theme }) => theme.media.mobile} {
    font-size: ${({ theme }) => theme.font.paragraphSize.m};
    line-height: 130%;
  }
`

export const StyledSmallTitle = styled(Paragraph)`
  margin: 50px 0 20px 0;
  font-weight: ${({ theme }) => theme.font.weight.bold};
  ${({ theme }) => theme.media.desktop} {
    font-size: ${({ theme }) => theme.font.paragraphSize.xl};
    margin: 70px 0 20px 0;
  }
`

export const ContactLabel = styled(Paragraph)`
  color: ${({ theme }) => theme.colors.white};
  margin: 10px 0;
  ${({ theme }) => theme.media.mobile} {
    font-size: ${({ theme }) => theme.font.paragraphSize.xxxs};
  }
`

export const StyledImg = styled.img`
  ${({ theme }) => theme.media.desktop} {
    margin-right: 5px;
  }
`
