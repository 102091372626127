import styled from "styled-components"
import { Button } from "../../atoms/Button/ButtonStyles"
import Heading from "../../atoms/Heading/Heading"
import { Paragraph } from "../../atoms/Paragraph/ParagraphStyles"

export const StyledHeaderText = styled.div`
  width: 40%;
  height: 400px;
  ${({ theme }) => theme.media.tablet} {
    width: 100%;
  }
`

export const StyledParagraph = styled(Paragraph)`
  width: 85%;
  ${({ theme }) => theme.media.desktop} {
    width: 100%;
  }
  ${({ theme }) => theme.media.tablet} {
    text-align: center;
    width: 50%;
  }
  ${({ theme }) => theme.media.mobile} {
    width: 100%;
    line-height: 150%;
    letter-spacing: 0;
  }
`

export const StyledButton = styled(Button)`
  background-color: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.black};
  width: 205px;
  height: 44px;
`

export const StyledHeading = styled(Heading)`
  ${({ theme }) => theme.media.tablet} {
    text-align: center;
  }
`
