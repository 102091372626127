import React from "react"
import {
  StyledProductsText,
  StyledParagraph,
  StyledButton,
} from "./ProductsTextStyles"
import Heading from "../../atoms/Heading/Heading"
import { FlexBox } from "../../atoms/FlexBox/FlexBoxStyles"

const ProductsText = ({ title, children, buttonName }) => {
  return (
    <StyledProductsText>
      <FlexBox flexDirection="column" justifyContent="space-around">
        <Heading level="3">{title}</Heading>
        <StyledParagraph>{children}</StyledParagraph>
        <StyledButton
          as="a"
          href="https://offex.pl/ofd-en.html"
          target="_blank"
        >
          {buttonName}
        </StyledButton>
      </FlexBox>
    </StyledProductsText>
  )
}
export default ProductsText
