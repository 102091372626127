import React from "react"
import {
  StyledContactInfo,
  StyledSmallTitle,
  ContactLabel,
  StyledImg,
  StyledParagraph,
} from "./ContactInfoStyles"
import Email from "../../../images/icon/Email.svg"
import Phone from "../../../images/icon/Phone.svg"

const ContactInfo = ({ children, informationTitle, email, phoneNumber }) => (
  <StyledContactInfo>
    <StyledParagraph>{children}</StyledParagraph>
    <StyledSmallTitle>{informationTitle}</StyledSmallTitle>
    <ContactLabel>
      <StyledImg src={Email} alt="email icon" /> {email}
    </ContactLabel>
    <ContactLabel>
      <StyledImg src={Phone} alt="phone icon" /> {phoneNumber}
    </ContactLabel>
  </StyledContactInfo>
)
export default ContactInfo
