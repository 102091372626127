import React from "react"
import { StyledBecomeLeader, SPAN } from "./BecomeLeaderStyles"
import LeaderText from "../../molecules/LeaderText/LeaderText"
import { FlexBox } from "../../atoms/FlexBox/FlexBoxStyles"

const BecomeLeader = () => (
  <StyledBecomeLeader id="become-our-dealer">
    <FlexBox justifyContent="center" alignItems="center">
      <LeaderText headingTitle="Become our dealer" buttonName="Contact form">
        Interested in our products? Want to start cooperation as a dealer?{" "}
        <br /> Use our contact form or just drop us a message at
        <SPAN> sales@offroad-express.com</SPAN>
      </LeaderText>
    </FlexBox>
  </StyledBecomeLeader>
)
export default BecomeLeader
