import styled from "styled-components"
import { Button } from "../../atoms/Button/ButtonStyles"
import NavigationLink from "../../atoms/NavigationLink/NavigationLink"

export const StyledNavigationMobile = styled.div`
  width: 100%;
  height: 100vh;
  z-index: 999999999999999999;
  position: fixed;
  background: rgba(23, 27, 23, 0.8);
  backdrop-filter: blur(24px);
`

export const HamburgerNavigation = styled.div`
  width: 80%;
  margin-top: 60px;
  max-height: 40px;

  @media (max-width: ${({ theme }) => theme.media.mobile}) {
    width: 90%;
    margin-top: 30px;
  }
`

export const Logo = styled.img`
  width: 114px;
`

export const CloseHamburger = styled(Button)`
  background-color: transparent;
  outline: none;
`

export const StyledNavigationMobileLinks = styled.ul`
  width: 100%;
  height: 100%;
  padding: 0;
`

export const StyledLink = styled(NavigationLink)`
  width: 90%;
  padding: 20px 0;
  font-size: ${({ theme }) => theme.font.paragraphSize.xl};
  font-weight: ${({ theme }) => theme.font.weight.regular};
  line-height: 120%;
  border-bottom: solid 1px ${({ theme }) => theme.colors.transparentBackground};
  &:last-child {
    border: none;
  }
`
