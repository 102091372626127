import React from "react"
import {
  StyledNavigation,
  NavigationButtons,
  StyledNavigationLink,
  MobileNavigation,
  Logo,
  HamburgerLogo,
} from "./NavigationStyles"
import logoImg from "../../../images/logo/logo3x.svg"
import HamburgerImg from "../../../images/icon/hamburger.svg"
import { Link } from "gatsby"
import { navigationArr } from "../../../utils/data/data"
import { FlexBox } from "../../atoms/FlexBox/FlexBoxStyles"

const Navigation = ({ onClick }) => (
  <StyledNavigation>
    <FlexBox flexDirection="row" justifyContent="space-between">
      <Link to="/">
        <Logo src={logoImg} alt="ofd company logo" />
      </Link>
      <NavigationButtons>
        <FlexBox
          justifyContent="flex-end"
          alignItems="center"
          alignItemsMobile="initial"
        >
          {navigationArr.map(navigationArr => (
            <StyledNavigationLink
              key={navigationArr.id}
              to={navigationArr.routing}
            >
              {navigationArr.name}
            </StyledNavigationLink>
          ))}
        </FlexBox>
      </NavigationButtons>
      <MobileNavigation onClick={onClick}>
        <HamburgerLogo src={HamburgerImg} alt="mobile menu icon" />
      </MobileNavigation>
    </FlexBox>
  </StyledNavigation>
)
export default Navigation
