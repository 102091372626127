import React from "react"
import {
  StyledWarranty,
  BackgroundImage,
  InfoBox,
  WarrantyBox,
} from "./WarrantyStyles"
import { graphql, useStaticQuery } from "gatsby"
import WarrantyText from "../../molecules/WarrantyText/WarrantyText"
import WarrantyInfo from "../../molecules/WarrantyInfo/WarrantyInfo"
import ShieldImg from "../../../images/icon/shield.svg"
import StarImg from "../../../images/icon/star.svg"
import Wrapper from "../../atoms/Wrapper/Wrapper"
import { FlexBox } from "../../atoms/FlexBox/FlexBoxStyles"

const Warranty = () => {
  const data = useStaticQuery(query)
  const imageData = data.desktop.childImageSharp.fluid

  return (
    <WarrantyBox id="warranty">
      <BackgroundImage
        imgStyle={{ objectPosition: "60%" }}
        fluid={imageData}
        alt="offroad jeep driving towards you in the forest"
      />
      <StyledWarranty>
        <FlexBox
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <Wrapper>
            <WarrantyText headingTitle="Warranty">
              The OFD bear is strong and powerful. He is not to be
              underestimated nor taken as a week point. <br /> <br /> In the
              unlikely case of the bear failure do not be afraid, we will
              provide you with a warranty replacement, hassle free.
            </WarrantyText>
            <InfoBox>
              <FlexBox alignItems="space-between" flexDirectionDesktop="column">
                <WarrantyInfo
                  imgPath={ShieldImg}
                  alt="24 month warranty icon"
                  name="24-month warranty"
                >
                  To be free of defects in material and workmanship for two
                  years from date of purchase.
                </WarrantyInfo>
                <WarrantyInfo
                  imgPath={StarImg}
                  alt="star icon"
                  name="Express shipping"
                >
                  All in-stock items will be shipped the next business day
                </WarrantyInfo>
              </FlexBox>
            </InfoBox>
          </Wrapper>
        </FlexBox>
      </StyledWarranty>
    </WarrantyBox>
  )
}

export const query = graphql`
  query {
    desktop: file(relativePath: { eq: "warranty.jpg" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

export default Warranty
