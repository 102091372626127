import React from "react"
import {
  StyledFooterSocialText,
  SocialButtons,
  StyledSocialLink,
  StyledParagraph,
} from "./FooterSocialTextStyles"
import FacebookLogo from "../../../images/logo/social/Fb.svg"
import TwitterLogo from "../../../images/logo/social/Twitter.svg"
import InstagramLogo from "../../../images/logo/social/Instagram.svg"
import { FlexBox } from "../../atoms/FlexBox/FlexBoxStyles"

const FooterSocialText = ({ children }) => {
  const socialArr = [
    {
      id: 1,
      image: FacebookLogo,
      alt: "Facebook logo",
      routing: "https://www.facebook.com/RideOFD",
    },
    {
      id: 2,
      image: TwitterLogo,
      alt: "Twitter logo",
      routing: "",
    },
    {
      id: 3,
      image: InstagramLogo,
      alt: "Instagram logo",
      routing: "",
    },
  ]

  return (
    <StyledFooterSocialText>
      <FlexBox
        alignSelf="center"
        flexDirection="column"
        justifyContent="center"
        alignItemsDesktop="center"
      >
        <SocialButtons>
          {socialArr.map(socialArr => (
            <StyledSocialLink as="a" key={socialArr.id} to={socialArr.routing}>
              <FlexBox justifyContent="center" alignItems="center">
                <img src={socialArr.image} alt={socialArr.alt}></img>
              </FlexBox>
            </StyledSocialLink>
          ))}
        </SocialButtons>
        <StyledParagraph>{children}</StyledParagraph>
      </FlexBox>
    </StyledFooterSocialText>
  )
}
export default FooterSocialText
