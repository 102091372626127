import styled from "styled-components"
import Img from "gatsby-image"

export const WarrantyBox = styled.section`
  width: 100%;
  height: auto;
  position: relative;
  ${({ theme }) => theme.media.desktop} {
    height: 1300px;
    margin-top: -200px;
  }
`

export const StyledWarranty = styled.div`
  width: 100%;
  position: absolute;
  bottom: 0;
  padding: 20rem 0 10rem 0;
  border-bottom: 2px solid #c4c4c433;
  ${({ theme }) => theme.media.desktop} {
    padding-top: 100px;
  }
  ${({ theme }) => theme.media.mobile} {
    padding: 30rem 0 5rem 0;
  }
`

export const BackgroundImage = styled(Img)`
  width: auto;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
`
export const InfoBox = styled.div`
  margin-top: 100px;
`
