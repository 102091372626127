import { createGlobalStyle} from "styled-components";

const GlobalStyle = createGlobalStyle`

    * {
        font-family: 'Poppins', sans-serif;
        margin: 0; 
        scroll-behavior: smooth;
    }
    body {
        background-color: ${({theme}) => theme.colors.backgroundGreen};
        color: ${({theme}) => theme.colors.white}; 
    }
`;

export default GlobalStyle;