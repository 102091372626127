export const navigationArr = [
  {
    id: 1,
    name: "Products",
    routing: "/#products",
  },
  {
    id: 2,
    name: "Become our dealer",
    routing: "/#become-our-dealer",
  },
  {
    id: 3,
    name: "Warranty",
    routing: "/#warranty",
  },
  {
    id: 4,
    name: "Contact us",
    routing: "/#contact",
  },
]
