import styled from "styled-components"
import { Paragraph } from "../../atoms/Paragraph/ParagraphStyles"
import NavigationLink from "../../atoms/NavigationLink/NavigationLink"

export const StyledFooterSocialText = styled.div`
  width: 50%;
  height: 100%;
  z-index: 999999;
  ${({ theme }) => theme.media.widescreen} {
    margin-left: -300px;
  }
  ${({ theme }) => theme.media.desktop} {
    margin: 0;
    width: 100%;
  }
`

export const SocialButtons = styled.div`
  display: flex;
  ${({ theme }) => theme.media.desktop} {
    margin-bottom: 50px;
  }
`

export const StyledSocialLink = styled(NavigationLink)`
  width: 60px;
  height: 60px;
  background-color: ${({ theme }) => theme.colors.backgroundGreen};
  margin-right: 10px;
`

export const StyledParagraph = styled(Paragraph)`
  width: 53%;
  color: ${({ theme }) => theme.colors.white};
  font-size: ${({ theme }) => theme.font.paragraphSize.xxl};
  font-weight: ${({ theme }) => theme.font.weight.bold};
  margin-top: 50px;
  line-height: 130%;
  ${({ theme }) => theme.media.desktop} {
    width: 100%;
    font-size: ${({ theme }) => theme.font.paragraphSize.xl};
    text-align: center;
  }
`
