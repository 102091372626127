import React from "react"
import {
  StyledProducts,
  ProductsBox,
  StyledImageBox,
  LeftStyledImageBox,
  BigImageMargin,
  SmallImageMargin,
  StyledProductsTitle,
  StyledGridBox,
} from "./ProductsStyles"
import ProductsText from "../../molecules/ProductsText/ProductsText"
import ProductsTextAd from "../../molecules/ProductsText/ProductsTextAd"
import { StyledImageBig } from "../../atoms/Image/ImageBigStyles"
import { StyledImageSmall } from "../../atoms/Image/ImageSmallStyles"
import { useStaticQuery, graphql } from "gatsby"
import { FlexBox } from "../../atoms/FlexBox/FlexBoxStyles"

const Products = () => {
  const data = useStaticQuery(query)
  return (
    <StyledProducts>
      <StyledGridBox
        gridTemplate="1fr 1fr 1fr 1fr / 50% 1fr"
        justifyContent="center"
        gridTemplateDesktop="auto 1fr 1fr 1fr / 1fr"
        justifyContentDesktop="start"
      >
        <ProductsBox id="products">
          <FlexBox
            flexDirection="column"
            justifyContent="flex-start"
            alignItems="flex-start"
          >
            <StyledProductsTitle headingTitle="Products">
              Driving to anywhere-you-want you can be sure to feel comfortable
              just like at home thanks to our gadgets and accessories.
            </StyledProductsTitle>
          </FlexBox>
        </ProductsBox>
        <ProductsBox>
          <StyledImageBox>
            <FlexBox
              alignSelf="space-around"
              alignItems="flex-end"
              justifyContentDesktop="flex-start"
            >
              <BigImageMargin
                fluid={data.first.childImageSharp.fluid}
                alt="fragment of black tool"
              />
              <StyledImageSmall
                fluid={data.second.childImageSharp.fluid}
                alt="fragment of silver tool"
              />
            </FlexBox>
          </StyledImageBox>
          <ProductsText title="Precise manufacturing" buttonName="Shop Now">
            Our Steel bumpers, sidesteps, roof racks, hoods, doors and even
            wheels are thoroughly design to withstand a force of mother nature
            and your urge to go deeper in to the wild dark forest.
          </ProductsText>
        </ProductsBox>
        <ProductsBox>
          <LeftStyledImageBox>
            <FlexBox
              alignSelf="space-around"
              alignItems="flex-end"
              justifyContentDesktop="flex-start"
            >
              <SmallImageMargin
                fluid={data.third.childImageSharp.fluid}
                alt="fragment of ventilation in the car"
              />
              <StyledImageBig
                fluid={data.fourth.childImageSharp.fluid}
                alt="fragment of side of the car"
              />
            </FlexBox>
          </LeftStyledImageBox>
          <ProductsTextAd
            headingTitle="Quality"
            extraText="Here at OFD we don’t compromise on quality."
            buttonName="Shop Now"
          >
            Quality welds, cuts and joints are a must in our factory. It not
            only looks good but it will last much longer.
          </ProductsTextAd>
        </ProductsBox>
        <ProductsBox>
          <StyledImageBox>
            <FlexBox
              alignSelf="space-around"
              alignItems="flex-end"
              justifyContentDesktop="flex-start"
            >
              <BigImageMargin
                fluid={data.fifth.childImageSharp.fluid}
                alt="fragment of silver tool"
              />
              <StyledImageSmall
                fluid={data.sixth.childImageSharp.fluid}
                alt="fragment of silver tool"
              />
            </FlexBox>
          </StyledImageBox>
          <ProductsText title="Designed to be used" buttonName="Shop Now">
            Storage solutions and organizers will help you keep your stuff in
            place even in the roughest terrain while Snorkels, exhaust or
            suspension parts are making sure you will get thru all the stuff
            that get thrown under your wheels.
          </ProductsText>
        </ProductsBox>
      </StyledGridBox>
    </StyledProducts>
  )
}

export const query = graphql`
  query MyQuery {
    first: file(relativePath: { eq: "products/tool2.png" }) {
      childImageSharp {
        fluid {
          aspectRatio
          base64
          sizes
          src
          srcSet
        }
      }
    }
    second: file(relativePath: { eq: "products/tool1.png" }) {
      childImageSharp {
        fluid {
          aspectRatio
          base64
          sizes
          src
          srcSet
        }
      }
    }
    third: file(relativePath: { eq: "products/tool4.png" }) {
      childImageSharp {
        fluid {
          aspectRatio
          base64
          sizes
          src
          srcSet
        }
      }
    }
    fourth: file(relativePath: { eq: "products/tool3.png" }) {
      childImageSharp {
        fluid {
          aspectRatio
          base64
          sizes
          src
          srcSet
        }
      }
    }
    fifth: file(relativePath: { eq: "products/tool5.png" }) {
      childImageSharp {
        fluid {
          aspectRatio
          base64
          sizes
          src
          srcSet
        }
      }
    }
    sixth: file(relativePath: { eq: "products/tool6.png" }) {
      childImageSharp {
        fluid {
          aspectRatio
          base64
          sizes
          src
          srcSet
        }
      }
    }
  }
`

export default Products
