import React from "react"
import {ThemeProvider} from 'styled-components';
import GlobalStyle from '../utils/GlobalStyle';
import Template from '../templates/Template';
import {theme} from '../utils/theme';
import SEO from "../components/seo"

const IndexPage = () => (
    <ThemeProvider theme={theme}>
        <>
            <GlobalStyle/>
            <SEO title="OFD"/>
            <Template></Template>
        </>
    </ThemeProvider>
  
)

export default IndexPage
