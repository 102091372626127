import styled from "styled-components"
import { Paragraph } from "../../atoms/Paragraph/ParagraphStyles"
import Heading from "../../atoms/Heading/Heading"

export const StyledWarrantyText = styled.div`
  width: 100%;
`

export const StyledHeading = styled(Heading)`
  margin-bottom: 40px;
`

export const StyledParagraph = styled(Paragraph)`
  width: 30%;
  font-weight: ${({ theme }) => theme.font.weight.bold};
  color: ${({ theme }) => theme.colors.white};
  ${({ theme }) => theme.media.widescreen} {
    width: 60%;
  }
  ${({ theme }) => theme.media.desktop} {
    width: 60%;
  }
  ${({ theme }) => theme.media.mobile} {
    width: 95%;
  }
`
