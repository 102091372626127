export const media = {
  mobile: "@media (max-width: 768px)",
  tablet: "@media (max-width: 1023px)",
  desktop: "@media (max-width: 1215px)",
  widescreen: "@media (max-width: 1450px)",
}

export const colors = {
  white: "#FFFFFF",
  transparentWhite: "rgba(255, 255, 255, 0.7)",
  backgroundGreen: "#171b17",
  black: "#0F110F",
  backgroundDarkGreen: "#0F110F",
  transparentBackground: "rgba(255, 255, 255, 0.1)",
  validation: "#FF0000",
}

export const weight = {
  regular: 300,
  bold: 600,
}

export const headingSize = {
  xxxl: "71px",
  xxl: "51px",
  xl: "44px",
  l: "41px",
  m: "30px",
  s: "26px",
  xs: "24px",
  xxs: "22px",
  xxxs: "19px",
}

export const paragraphSize = {
  xxl: "30px",
  xl: "24px",
  l: "22px",
  m: "20px",
  s: "19px",
  xs: "18px",
  xxs: "15px",
  xxxs: "14px",
  xxxxs: "13px",
}

export const theme = {
  colors,
  font: {
    weight,
    headingSize,
    paragraphSize,
  },
  media,
}
