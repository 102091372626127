import styled from "styled-components"
import Img from "gatsby-image"
import Wrapper from "../../atoms/Wrapper/Wrapper"

export const HeaderBox = styled.header`
  width: 100%;
  height: 1000px;
  position: relative;
`

export const StyledHeader = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  height: 1000px;
  background-repeat: no-repeat;
  background-size: cover;
`

export const StyledWrapper = styled(Wrapper)`
  margin-top: 120px;
  ${({ theme }) => theme.media.tablet} {
    margin-top: 100px;
  }
`

export const BackgroundImage = styled(Img)`
  width: auto;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
`

export const Image = styled.img`
  ${({ theme }) => theme.media.widescreen} {
    width: 40%;
  }
  ${({ theme }) => theme.media.tablet} {
    display: none;
  }
`
