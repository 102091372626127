import styled from "styled-components"

export const StyledWrapper = styled.div`
  width: 70%;
  ${({ theme }) => theme.media.widescreen} {
    width: 80%;
  }
  ${({ theme }) => theme.media.desktop} {
    width: 87%;
  }
  ${({ theme }) => theme.media.tablet} {
    width: 90%;
  }
`
