import React from "react"
import {
  BackgroundImage,
  StyledAdvert,
  MobileBackgroundImage,
} from "./AdvertStyles"
import { graphql, useStaticQuery } from "gatsby"
import AdvertText from "../../molecules/AdvertText/AdvertText"
import { FlexBox } from "../../atoms/FlexBox/FlexBoxStyles"

const Advert = ({ className }) => {
  const data = useStaticQuery(query)
  const imageData = data.desktop.childImageSharp.fluid
  return (
    <StyledAdvert>
      <FlexBox
        justifyContent="center"
        alignItems="center"
        flexDirectionMobile="column-reverse"
      >
        <BackgroundImage
          className={className}
          fluid={imageData}
          alt="green jeep in the forest"
        ></BackgroundImage>
        <MobileBackgroundImage
          className={className}
          fluid={imageData}
          alt="green jeep in the forest"
        ></MobileBackgroundImage>
        <AdvertText>
          With the OFD parts and accessories you are fully cover. The only thing
          that we can’t provide you with is a cold beverage. Yet.
        </AdvertText>
      </FlexBox>
    </StyledAdvert>
  )
}

export const query = graphql`
  query {
    desktop: file(relativePath: { eq: "image28.png" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

export default Advert
