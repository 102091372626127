import styled from "styled-components"
import { Button } from "../../atoms/Button/ButtonStyles"
import { Toast } from "../../atoms/Toast/Toast"
import Heading from "../../atoms/Heading/Heading"
import { Paragraph } from "../../atoms/Paragraph/ParagraphStyles"

export const StyledContactForm = styled.div`
  width: auto;
  margin-bottom: 100px;
  ${({ theme }) => theme.media.desktop} {
    margin: 0;
    width: 90%;
  }
  ${({ theme }) => theme.media.mobile} {
    width: 100%;
  }
`

export const StyledHeading = styled(Heading)`
  color: ${({ theme }) => theme.colors.transparentWhite};
  margin-bottom: 20px;
`

export const Form = styled.form`
  ${({ theme }) => theme.media.desktop} {
    width: 100%;
  }
`

export const Label = styled.label`
  color: ${({ theme }) => theme.colors.backgroundGreen};
  font-size: 0.1px;
  ${({ theme }) => theme.media.mobile} {
    width: 100%;
  }
`

export const Input = styled.input`
  width: 430px;
  height: 25px;
  margin: 20px 0;
  background-color: transparent;
  border-top: none;
  border-right: none;
  border-left: none;
  border-bottom: 1px solid ${({ theme }) => theme.colors.transparentWhite};
  outline: none;
  color: ${({ theme }) => theme.colors.white};
  font-size: ${({ theme }) => theme.font.paragraphSize.xs};
  font-weight: ${({ theme }) => theme.font.weight.bold};
  &::placeholder {
    color: ${({ theme }) => theme.colors.white};
    font-size: ${({ theme }) => theme.font.paragraphSize.xxs};
    font-weight: ${({ theme }) => theme.font.weight.bold};
  }
  &:focus {
    border-bottom: 1px solid ${({ theme }) => theme.colors.white};
    &::placeholder {
      color: transparent;
    }
  }
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-transition: "color 9999s ease-out, background-color 9999s ease-out";
    -webkit-transition-delay: 9999s;
  }
  ${({ theme }) => theme.media.desktop} {
    font-size: ${({ theme }) => theme.font.paragraphSize.xs};
  }
  ${({ theme }) => theme.media.mobile} {
    width: 100%;
  }
`

export const Textarea = styled.textarea`
  width: 430px;
  height: 100px;
  background-color: transparent;
  margin: 20px 0;
  outline: none;
  color: ${({ theme }) => theme.colors.white};
  font-size: ${({ theme }) => theme.font.paragraphSize.xs};
  font-weight: ${({ theme }) => theme.font.weight.bold};
  &::placeholder {
    color: ${({ theme }) => theme.colors.transparentWhite};
    font-size: ${({ theme }) => theme.font.paragraphSize.xxs};
    font-weight: ${({ theme }) => theme.font.weight.bold};
    padding: 10px;
  }
  &:focus {
    border: 1px solid ${({ theme }) => theme.colors.white};
    &::placeholder {
      color: transparent;
    }
  }
  ${({ theme }) => theme.media.desktop} {
    font-size: ${({ theme }) => theme.font.paragraphSize.xs};
  }
  ${({ theme }) => theme.media.mobile} {
    width: 100%;
  }
`

export const StyledFormButton = styled(Button)`
  align-self: flex-end;
  width: 205px;
  height: 44px;
  ${({ theme }) => theme.media.desktop} {
    align-self: flex-start;
    width: 435px;
  }
  ${({ theme }) => theme.media.mobile} {
    width: 100%;
  }
`

export const ValidationText = styled(Paragraph)`
  color: ${({ theme }) => theme.colors.validation};
  font-size: ${({ theme }) => theme.font.paragraphSize.xxxxs};
  margin-bottom: 10px;
  ${({ theme }) => theme.media.mobile} {
    font-size: ${({ theme }) => theme.font.paragraphSize.xxxxs};
  }
`

export const SuccesfulToast = styled(Toast)`
  background-color: #28a745;
  margin-bottom: 20px;
`

export const FailedToast = styled(Toast)`
  background-color: #dc3545;
  margin-bottom: 20px;
`

export const ToastImage = styled.img`
  width: 20px;
  margin-right: 10px;
`
