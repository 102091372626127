import React from "react"
import {
  StyledContactForm,
  StyledHeading,
  Form,
  Label,
  Input,
  Textarea,
  StyledFormButton,
  ValidationText,
  SuccesfulToast,
  FailedToast,
  ToastImage,
} from "./ContactFormStyles"
import { useFormik } from "formik"
import emailjs, { init } from "emailjs-com"
import SuccessImg from "../../../images/icon/tick.svg"
import FailedImg from "../../../images/icon/close.svg"
import { FlexBox } from "../../atoms/FlexBox/FlexBoxStyles"

init("user_1D6pYY8YjqZc20l3BMyhk")

const ContactForm = ({ className }) => {
  const formikForm = useFormik({
    initialValues,
    validate,
    onSubmit,
  })

  return (
    <StyledContactForm className={className}>
      <StyledHeading level="5">Contact form</StyledHeading>
      <Form onSubmit={formikForm.handleSubmit}>
        <FlexBox flexDirection="column">
          <Label>
            <Input
              id="name"
              type="text"
              name="name"
              placeholder="Name"
              {...formikForm.getFieldProps("name")}
            />
          </Label>
          {formikForm.touched.name && formikForm.errors.name ? (
            <ValidationText>{formikForm.errors.name}</ValidationText>
          ) : null}
          <Label>
            Email
            <Input
              id="email"
              type="email"
              name="email"
              placeholder="E-mail"
              {...formikForm.getFieldProps("email")}
            />
          </Label>
          {formikForm.touched.email && formikForm.errors.email ? (
            <ValidationText>{formikForm.errors.email}</ValidationText>
          ) : null}
          <Label>
            Title
            <Input
              id="title"
              type="text"
              name="title"
              placeholder="Title"
              {...formikForm.getFieldProps("title")}
            />
          </Label>
          {formikForm.touched.title && formikForm.errors.title ? (
            <ValidationText>{formikForm.errors.title}</ValidationText>
          ) : null}
          <Label>
            Message
            <Textarea
              name="message"
              id="message"
              rows="5"
              placeholder="Message"
              {...formikForm.getFieldProps("message")}
            />
          </Label>
          {formikForm.touched.message && formikForm.errors.message ? (
            <ValidationText>{formikForm.errors.message}</ValidationText>
          ) : null}

          {formikForm.status && formikForm.status.success ? (
            <SuccesfulToast>
              <ToastImage src={SuccessImg} alt="operation successed" /> Email
              sent
            </SuccesfulToast>
          ) : null}
          {formikForm.status && formikForm.status.failed ? (
            <FailedToast>
              {" "}
              <ToastImage src={FailedImg} alt="operation failed" /> Server Error
            </FailedToast>
          ) : null}
          <StyledFormButton type="submit">Send</StyledFormButton>
        </FlexBox>
      </Form>
    </StyledContactForm>
  )
}

const initialValues = {
  name: "",
  email: "",
  title: "",
  message: "",
}

const validate = values => {
  const errors = {}
  if (!values.name) {
    errors.name = "Name required"
  } else if (values.name.length > 30) {
    errors.name = "Name must be 30 characters or less"
  }

  if (!values.title) {
    errors.title = "Title required"
  } else if (values.title.length > 50) {
    errors.title = "Title must contain 50 characters or less"
  }

  if (!values.email) {
    errors.email = "Email required"
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = "Invalid email address"
  }

  if (!values.message) {
    errors.message = "Message required"
  } else if (values.message.length > 400) {
    errors.message = "Message must be 400 characters or less"
  }

  return errors
}

const onSubmit = (values, actions) => {
  emailjs
    .send("service_qfj6trk", "template_s0nhhy7", {
      name: values.name,
      title: values.title,
      email: values.email,
      message: values.message,
    })
    .then(response => actions.setStatus({ success: "Working" }))
    .catch(error => actions.setStatus({ failed: "Not Working" }))
}

export default ContactForm
