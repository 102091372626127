import styled from "styled-components"

export const GridBox = styled.div.attrs(props => ({
  gridTemplate: props.gridTemplate,
  justifyContent: props.justifyContent,

  gridTemplateWidescreen: props.gridTemplateWidescreen,
  justifyContentWidescreen: props.justifyContentWidescreen,

  gridTemplateDesktop: props.gridTemplateDesktop,
  justifyContentDesktop: props.justifyContentDesktop,

  gridTemplateTablet: props.gridTemplateTablet,
  justifyContentTablet: props.justifyContentTablet,

  gridTemplateMobile: props.gridTemplateMobile,
  justifyContentMobile: props.justifyContentMobile,
}))`
  height: 100%;
  display: grid;
  grid-template: ${props => props.gridTemplate};
  justify-content: ${props => props.justifyContent};
  ${({ theme }) => theme.media.widescreen} {
    grid-template: ${props => props.gridTemplateWidescreen};
    justify-content: ${props => props.justifyContentWidescreen};
  }
  ${({ theme }) => theme.media.desktop} {
    grid-template: ${props => props.gridTemplateDesktop};
    justify-content: ${props => props.justifyContentDesktop};
  }
  ${({ theme }) => theme.media.tablet} {
    grid-template: ${props => props.gridTemplateTablet};
    justify-content: ${props => props.justifyContentTablet};
  }
  ${({ theme }) => theme.media.mobile} {
    grid-template: ${props => props.gridTemplateMobile};
    justify-content: ${props => props.justifyContentMobile};
  }
`
