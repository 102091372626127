import React from "react"
import {
  StyledFooter,
  BackgroundImage,
  FooterContent,
  CopyrightParagraph,
} from "./FooterStyles"
import { graphql, useStaticQuery } from "gatsby"
import FooterSocialText from "../../molecules/FooterSocialText/FooterSocialText"
import { FlexBox } from "../../atoms/FlexBox/FlexBoxStyles"

const Footer = () => {
  const data = useStaticQuery(query)
  const imageData = data.desktop.childImageSharp.fluid
  return (
    <StyledFooter>
      <FlexBox
        flexDirection="column"
        justifyContent="space-between"
        alignItems="center"
      >
        <FooterContent>
          <FlexBox flexDirectionDesktop="column">
            <BackgroundImage
              fluid={imageData}
              alt="silver land rover driving through the mud in the forest"
            ></BackgroundImage>
            <FooterSocialText>
              Bears are usually antisocial animals but don’t be afraid to join
              in with others.
            </FooterSocialText>
          </FlexBox>
        </FooterContent>
        <CopyrightParagraph>
          Copyright by ofd.com 2020 All rights reserved
        </CopyrightParagraph>
      </FlexBox>
    </StyledFooter>
  )
}

export const query = graphql`
  query {
    desktop: file(relativePath: { eq: "Footer.png" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

export default Footer
