import styled from "styled-components"
import { Paragraph } from "../../atoms/Paragraph/ParagraphStyles"
import Heading from "../../atoms/Heading/Heading"

export const StyledWarrantyInfo = styled.div`
  width: 50%;
  &:last-child {
    margin-left: 50px;
  }
  ${({ theme }) => theme.media.desktop} {
    width: 100%;
    margin-bottom: 50px;
    &:last-child {
      margin-left: 0;
    }
  }
`

export const StyledHeading = styled(Heading)`
  margin: 20px 0;
`

export const StyledParagraph = styled(Paragraph)`
  width: 65%;
  color: ${({ theme }) => theme.colors.transparentWhite};
  ${({ theme }) => theme.media.desktop} {
    font-size: ${({ theme }) => theme.font.paragraphSize.s};
    width: 70%;
  }
  ${({ theme }) => theme.media.mobile} {
    line-height: 150%;
    width: 95%;
  }
`
