import React from "react"
import {
  StyledContact,
  StyledHeading,
  StyledWrapper,
  StyledForm,
  Brush,
} from "./ContactStyles"
import ContactInfo from "../../molecules/ContactInfo/ContactInfo"
// import ContactForm from '../../molecules/ContactForm/ContactForm';
import brush from "../../../images/contact-brush.png"
import { FlexBox } from "../../atoms/FlexBox/FlexBoxStyles"

const Contact = () => (
  <StyledContact id="contact">
    <FlexBox flexDirection="column" alignItems="center" justifyContent="center">
      <StyledWrapper>
        <FlexBox flexDirectionDesktop="column">
          <StyledHeading level="1">Have a question or concern?</StyledHeading>
        </FlexBox>
      </StyledWrapper>
      <StyledWrapper>
        <FlexBox flexDirectionDesktop="column">
          <ContactInfo
            informationTitle="Direct contact"
            email="sales@offroad-express.com"
            phoneNumber="+48 517 653 636"
          >
            Please contact us, we don’t <br /> bite. Watch the bear though.
          </ContactInfo>
          <StyledForm></StyledForm>
        </FlexBox>
      </StyledWrapper>
      <Brush src={brush} />
    </FlexBox>
  </StyledContact>
)
export default Contact
