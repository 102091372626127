import styled from "styled-components"

export const H1 = styled.h1`
  line-height: 100%;
  font-size: ${({ theme }) => theme.font.headingSize.xxxl};
  ${({ theme }) => theme.media.tablet} {
    font-size: ${({ theme }) => theme.font.headingSize.xxl};
    line-height: 120%;
  }
  ${({ theme }) => theme.media.mobile} {
    font-size: ${({ theme }) => theme.font.headingSize.l};
    line-height: 130%;
  }
`

export const H2 = styled.h2`
  font-size: ${({ theme }) => theme.font.headingSize.xl};
  ${({ theme }) => theme.media.mobile} {
    font-size: ${({ theme }) => theme.font.headingSize.m};
  }
`

export const H3 = styled.h3`
  font-size: ${({ theme }) => theme.font.headingSize.m};
`

export const H4 = styled.h4`
  font-size: ${({ theme }) => theme.font.headingSize.s};
  ${({ theme }) => theme.media.mobile} {
    font-size: ${({ theme }) => theme.font.headingSize.xs};
  }
`

export const H5 = styled.h5`
  font-size: ${({ theme }) => theme.font.headingSize.xxs};
  ${({ theme }) => theme.media.mobile} {
    font-size: ${({ theme }) => theme.font.headingSize.xxxs};
  }
`
