import styled from "styled-components"
import { StyledImageBig } from "../../atoms/Image/ImageBigStyles"
import { StyledImageSmall } from "../../atoms/Image/ImageSmallStyles"
import ProductsTitle from "../../molecules/ProductsTitle/ProductsTitle"
import Wrapper from "../../atoms/Wrapper/Wrapper"
import { GridBox } from "../../atoms/GridBox/GridBoxStyles"

export const StyledProducts = styled(Wrapper)`
  height: auto;
  margin: 0 auto;
  ${({ theme }) => theme.media.desktop} {
    overflow-x: hidden;
  }
  ${({ theme }) => theme.media.mobile} {
    padding: 0 20px;
  }
`

export const StyledGridBox = styled(GridBox)`
  grid-template-areas:
    "First Second"
    "Third Second"
    "Third Fourth"
    ". Fourth";
  ${({ theme }) => theme.media.desktop} {
    grid-template-areas:
      "First"
      "Second"
      "Third"
      "Fourth";
  }
`

export const ProductsBox = styled.div`
  width: 100%;
  &:nth-child(1) {
    grid-area: First;
  }
  &:nth-child(2) {
    grid-area: Second;
    margin-left: 100px;
  }
  &:nth-child(3) {
    grid-area: Third;
  }
  &:nth-child(4) {
    grid-area: Fourth;
    margin-left: 100px;
  }
  ${({ theme }) => theme.media.desktop} {
    width: 90%;
    height: 100%;
    margin: 0;
    &:nth-child(2) {
      margin-left: 0;
    }
    &:nth-child(4) {
      margin-left: 0;
    }
  }
`

export const StyledImageBox = styled.div`
  margin-top: 100px;
  margin-right: 1.5%;
  ${({ theme }) => theme.media.widescreen} {
    width: 75%;
  }
  ${({ theme }) => theme.media.desktop} {
    width: auto;
    margin-right: 3%;
  }
`

export const LeftStyledImageBox = styled(StyledImageBox)`
  margin-right: 15%;
`

export const BigImageMargin = styled(StyledImageBig)`
  margin-right: 30px;
`

export const SmallImageMargin = styled(StyledImageSmall)`
  margin-right: 30px;
`
export const StyledProductsTitle = styled(ProductsTitle)`
  margin-left: 50px;
  ${({ theme }) => theme.media.desktop} {
    margin-left: 0;
  }
`
