import styled from "styled-components"
import { Paragraph } from "../../atoms/Paragraph/ParagraphStyles"
import { Button } from "../../atoms/Button/ButtonStyles"

export const StyledLeaderText = styled.div`
  height: 70%;
  text-align: center;
  margin: 0 20px;
  ${({ theme }) => theme.media.tablet} {
  }
  ${({ theme }) => theme.media.mobile} {
    height: 40%;
  }
`

export const StyledParagraph = styled(Paragraph)`
  position: relative;
  z-index: 10;
  font-size: ${({ theme }) => theme.font.paragraphSize.m};
`

export const StyledButton = styled(Button)`
  background-color: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.black};
  width: 205px;
  height: 44px;
  ${({ theme }) => theme.media.tablet} {
    display: none;
  }
`
