import React from "react"
import { StyledProductsTitle, StyledParagraph } from "./ProductsTitleStyles"
import Heading from "../../atoms/Heading/Heading"
import { FlexBox } from "../../atoms/FlexBox/FlexBoxStyles"

const ProductsTitle = ({ className, headingTitle, children }) => (
  <StyledProductsTitle className={className}>
    <FlexBox flexDirection="column" justifyContent="space-around">
      <Heading level="1">{headingTitle}</Heading>
      <StyledParagraph>{children}</StyledParagraph>
    </FlexBox>
  </StyledProductsTitle>
)
export default ProductsTitle
