import styled from "styled-components"

export const StyledBecomeLeader = styled.section`
  width: 100%;
  height: 500px;
  background-color: ${({ theme }) => theme.colors.backgroundDarkGreen};
  ${({ theme }) => theme.media.mobile} {
    height: 100vh;
  }
`
export const SPAN = styled.span`
  color: ${({ theme }) => theme.colors.white};
  font-weight: ${({ theme }) => theme.font.weight.bold};
  white-space: nowrap;
`
