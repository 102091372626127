import styled from "styled-components"
import ContactForm from "../../molecules/ContactForm/ContactForm"
import Heading from "../../atoms/Heading/Heading"
import Wrapper from "../../atoms/Wrapper/Wrapper"

export const StyledContact = styled.section`
  width: 100%;
  height: 1000px;
  position: relative;
  ${({ theme }) => theme.media.desktop} {
    height: 1100px;
  }
`

export const StyledHeading = styled(Heading)`
  ${({ theme }) => theme.media.mobile} {
    padding-right: 10%;
  }
`

export const StyledWrapper = styled(Wrapper)`
  margin-top: 100px;
  height: auto;
`

export const StyledForm = styled(ContactForm)`
  margin-left: 100px;
  ${({ theme }) => theme.media.desktop} {
    margin-left: 0;
    margin-bottom: 50px;
  }
`

export const Brush = styled.img`
  z-index: 100;
  position: absolute;
  width: 100%;
  bottom: -30%;
  margin-top: 200px;
  ${({ theme }) => theme.media.desktop} {
    bottom: -20%;
  }

  ${({ theme }) => theme.media.tablet} {
    bottom: -16%;
  }

  ${({ theme }) => theme.media.mobile} {
    bottom: -25%;
    height: 300px;
  }
`
