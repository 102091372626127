import styled from "styled-components"

export const Paragraph = styled.p`
  font-size: ${({ theme }) => theme.font.paragraphSize.l};
  line-height: 130%;
  color: ${({ theme }) => theme.colors.transparentWhite};
  ${({ theme }) => theme.media.mobile} {
    font-size: ${({ theme }) => theme.font.paragraphSize.s};
  }
`
