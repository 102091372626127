import styled from "styled-components"
import NavigationLink from "../../atoms/NavigationLink/NavigationLink"
import { Button } from "../../atoms/Button/ButtonStyles"

export const StyledNavigation = styled.nav`
  padding-top: 60px;
  width: 100%;
  ${({ theme }) => theme.media.mobile} {
    padding-top: 30px;
  }
`

export const Logo = styled.img`
  width: 215px;

  ${({ theme }) => theme.media.tablet} {
    width: 214px;
  }
  ${({ theme }) => theme.media.mobile} {
    padding-left: 10px;
    width: 114px;
  }
`

export const NavigationButtons = styled.ul`
  width: auto;
`

export const StyledNavigationLink = styled(NavigationLink)`
  padding: 10px 20px;
  margin-left: 30px;
  &:hover {
    background-color: ${({ theme }) => theme.colors.black};
  }
  ${({ theme }) => theme.media.tablet} {
    display: none;
  }
`

export const MobileNavigation = styled(Button)`
  display: none;
  ${({ theme }) => theme.media.tablet} {
    display: block;
    background-color: transparent;
    outline: none;
  }
`

export const HamburgerLogo = styled.img`
  ${({ theme }) => theme.media.tablet} {
    width: 40px;
  }
  ${({ theme }) => theme.media.mobile} {
    width: 24px;
  }
`
