import React from "react"
import {
  StyledNavigationMobile,
  HamburgerNavigation,
  Logo,
  CloseHamburger,
  StyledNavigationMobileLinks,
  StyledLink,
} from "./NavigationMobileStyles"
import logoImg from "../../../images/logo/logo3x.svg"
import CloseImg from "../../../images/icon/menu-close.svg"
import { navigationArr } from "../../../utils/data/data"
import { FlexBox } from "../../atoms/FlexBox/FlexBoxStyles"

const NavigationMobile = ({ onClick }) => (
  <StyledNavigationMobile>
    <FlexBox
      flexDirection="column"
      alignItems="center"
      justifyContent="space-between"
    >
      <HamburgerNavigation>
        <FlexBox flexDirection="row" justifyContent="space-between">
          <Logo src={logoImg} alt="ofd company logo" />
          <CloseHamburger onClick={onClick}>
            <img src={CloseImg} alt="close menu icon" />
          </CloseHamburger>
        </FlexBox>
      </HamburgerNavigation>
      <StyledNavigationMobileLinks>
        <FlexBox
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          {navigationArr.map(navigationArr => (
            <StyledLink
              key={navigationArr.id}
              onClick={onClick}
              to={navigationArr.routing}
            >
              {navigationArr.name}
            </StyledLink>
          ))}
        </FlexBox>
      </StyledNavigationMobileLinks>
    </FlexBox>
  </StyledNavigationMobile>
)
export default NavigationMobile
