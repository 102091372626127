import styled from "styled-components"

export const FlexBox = styled.div.attrs(props => ({
  justifyContent: props.justifyContent || "flex-start",
  alignItems: props.alignItems || "flex-start",
  flexDirection: props.flexDirection || "row",
  alignSelf: props.alignSelf || "auto",

  justifyContentWidescreen:
    props.justifyContentWidescreen || props.justifyContent,
  alignItemsWidescreen: props.alignItemsWidescreen || props.alignItems,
  flexDirectionWidescreen: props.flexDirectionWidescreen || props.flexDirection,

  justifyContentDesktop:
    props.justifyContentyDesktop || props.justifyContentWidescreen,
  alignItemsDesktop: props.alignItemsDesktop || props.alignItemsWidescreen,
  flexDirectionDesktop:
    props.flexDirectionDesktop || props.flexDirectionWidescreen,

  justifyContentTablet:
    props.justifyContentTablet || props.justifyContentyDesktop,
  alignItemsTablet: props.alignItemsTablet || props.alignItemsDesktop,
  flexDirectionTablet: props.flexDirectionTablet || props.flexDirectionDesktop,

  justifyContentMobile:
    props.justifyContentMobile || props.justifyContentTablet,
  alignItemsMobile: props.alignItemsMobile || props.alignItemsTablet,
  flexDirectionMobile: props.flexDirectionMobile || props.flexDirectionTablet,
}))`
  display: flex;
  height: 100%;
  justify-content: ${props => props.justifyContent};
  align-items: ${props => props.alignItems};
  flex-direction: ${props => props.flexDirection};
  align-self: ${props => props.alignSelf};

  ${({ theme }) => theme.media.widescreen} {
    justify-content: ${props => props.justifyContentWidescreen};
    align-items: ${props => props.alignItemsWidescreen};
    flex-direction: ${props => props.flexDirectionWidescreen};
  }
  ${({ theme }) => theme.media.desktop} {
    justify-content: ${props => props.justifyContentDesktop};
    align-items: ${props => props.alignItemsDesktop};
    flex-direction: ${props => props.flexDirectionDesktop};
  }
  ${({ theme }) => theme.media.tablet} {
    justify-content: ${props => props.justifyContentTablet};
    align-items: ${props => props.alignItemsTablet};
    flex-direction: ${props => props.flexDirectionTablet};
  }
  ${({ theme }) => theme.media.mobile} {
    justify-content: ${props => props.justifyContentMobile};
    align-items: ${props => props.alignItemsMobile};
    flex-direction: ${props => props.flexDirectionMobile};
  }
`
