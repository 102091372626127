import styled from "styled-components"
import { Paragraph } from "../../atoms/Paragraph/ParagraphStyles"

export const StyledAdvertText = styled.div`
  width: 40%;
  z-index: 999999;
  display: flex;
  align-items: flex-end;
  ${({ theme }) => theme.media.mobile} {
    width: 90%;
    margin-left: 20px;
  }
`

export const StyledParagraph = styled(Paragraph)`
  font-size: ${({ theme }) => theme.font.paragraphSize.xxl};
  font-weight: ${({ theme }) => theme.font.weight.bold};
  color: ${({ theme }) => theme.colors.white};
  margin-left: -45px;
  width: 55%;
  ${({ theme }) => theme.media.widescreen} {
    width: 70%;
  }
  ${({ theme }) => theme.media.tablet} {
    width: 85%;
  }
  ${({ theme }) => theme.media.mobile} {
    font-size: ${({ theme }) => theme.font.paragraphSize.xxl};
    margin-left: 0;
    width: 95%;
    margin-bottom: 40px;
  }
`
