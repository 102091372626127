import React from "react"
import { H1, H2, H3, H4, H5 } from "./HeadingStyles"
import PropTypes from "prop-types"

const Heading = ({ className, children, level }) => {
  const validLevels = [H1, H2, H3, H4, H5]
  const HeadingLevel = validLevels[level - 1]

  return (
    <HeadingLevel className={className} level={level}>
      {children}
    </HeadingLevel>
  )
}

Heading.propTypes = {
  level: PropTypes.oneOf(["1", "2", "3", "4", "5"]),
}

export default Heading
