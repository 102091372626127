import styled from "styled-components"
import { Link } from "gatsby"

export const LinkBasic = styled(Link)`
  color: ${({ theme }) => theme.colors.white};
  font-weight: ${({ theme }) => theme.font.weight.bold};
  background-color: transparent;
  border: none;
  font-size: ${({ theme }) => theme.font.paragraphSize.xxxs};
  outline: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
`
