import React from "react"
import {
  StyledLeaderText,
  StyledParagraph,
  StyledButton,
} from "./LeaderTextStyles"
import Heading from "../../atoms/Heading/Heading"
import { FlexBox } from "../../atoms/FlexBox/FlexBoxStyles"

const LeaderText = ({ children, headingTitle, buttonName }) => (
  <StyledLeaderText>
    <FlexBox
      flexDirection="column"
      justifyContent="space-around"
      alignItems="center"
    >
      <Heading level="2">{headingTitle}</Heading>
      <StyledParagraph>{children}</StyledParagraph>
      <StyledButton as="a" href="#contact">
        {buttonName}
      </StyledButton>
    </FlexBox>
  </StyledLeaderText>
)
export default LeaderText
