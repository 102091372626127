import React from "react"
import {
  StyledWarrantyInfo,
  StyledHeading,
  StyledParagraph,
} from "./WarrantyInfoStyles"

const WarrantyInfo = ({ className, imgPath, name, children }) => (
  <StyledWarrantyInfo className={className}>
    <img src={imgPath} alt="warranty-icon" />
    <StyledHeading level="4">{name}</StyledHeading>
    <StyledParagraph>{children}</StyledParagraph>
  </StyledWarrantyInfo>
)
export default WarrantyInfo
