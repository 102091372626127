import styled from "styled-components"
import Img from "gatsby-image"
import { Paragraph } from "../../atoms/Paragraph/ParagraphStyles"

export const StyledFooter = styled.footer`
  width: 100%;
  height: 700px;
  padding-top: 6rem;
  background-color: ${({ theme }) => theme.colors.backgroundDarkGreen};
  ${({ theme }) => theme.media.desktop} {
    height: 1100px;
  }
`

export const FooterContent = styled.div`
  width: 100%;
  height: 100%;
`

export const BackgroundImage = styled(Img)`
  left: 280px;
  width: 60%;
  height: 100%;
  ${({ theme }) => theme.media.widescreen} {
    width: 100%;
    left: 0px;
  }
  ${({ theme }) => theme.media.desktop} {
    height: 700px;
  }
  ${({ theme }) => theme.media.mobile} {
    background-size: 100%;
    height: 700px;
  }
`

export const CopyrightParagraph = styled(Paragraph)`
  position: relative;
  bottom: 50px;
  z-index: 99999999;
  font-size: ${({ theme }) => theme.font.paragraphSize.xxxs};
  ${({ theme }) => theme.media.desktop} {
    text-align: center;
    font-size: ${({ theme }) => theme.font.paragraphSize.xxxxs};
  }
`
