import React from "react"
import { StyledAdvertText, StyledParagraph } from "./AdvertTextStyles"
import { FlexBox } from "../../atoms/FlexBox/FlexBoxStyles"

const AdvertText = ({ children }) => (
  <StyledAdvertText>
    <FlexBox alignItems="flex-end">
      <StyledParagraph>{children}</StyledParagraph>
    </FlexBox>
  </StyledAdvertText>
)
export default AdvertText
